import '@babel/polyfill';
import $ from 'jquery';

import 'owl.carousel';
import 'jquery-validation';
import 'intl-tel-input';

import Form from './form';
import initComponents from './components';

initComponents();

//fixed header on scroll
window.onscroll = function() {
	myFunction();
};


var sticky = $('header').offset().top;

// Add the sticky class to the header when you reach its scroll position. Remove "sticky" when you leave the scroll position
function myFunction() {
	if ( $(window).scrollTop() > sticky) {
		$('header').addClass('sticky');
	} else {
		$('header').removeClass('sticky');
	}
}

$('document').ready(function() {
	$('.closeNoti').on('click',function(){
		$('.notification-container').removeClass('open');
	})

	$('.blue-form').find('form').validate();
	$('.homeIntro').find('form').validate();
	$('.white-form').find('form').validate();

	$('.introForm form').find('button[type="submit"]').on('click',function(e){
		e.preventDefault();
		var selectedVal = $(this).parents('form').find('select:first-of-type').val();
		selectedVal = selectedVal.replace(/\s/g,'');
		console.log(selectedVal)
		
		if(selectedVal !== null) {
			$([document.documentElement, document.body]).animate({
				scrollTop: $("#card_"+selectedVal).offset().top - 180
			}, 100);
			$('.card').removeClass('active');
			$("#card_"+selectedVal).addClass('active');
		}

	});
	
	// nav scroll
	
	$('nav a').on('click', function(event) {
	    $('nav a').removeClass('active');
		$(this).addClass('active');
		if(!$('header').hasClass('innerPage'))
		{
		if (this.hash !== '') {
			event.preventDefault();

			var hash = this.hash;
           
			$('body').removeClass('--menu-open')
			$('header nav').removeClass('--menu-open')

			if ($('header i.material-icons').attr('icon') == 'menu') {
				$('header i.material-icons').attr('icon', 'close');
			} else {
				$('header i.material-icons').attr('icon', 'menu');
			}
        if($(hash).length > 0){
			$('html, body').animate(
				{
					scrollTop: $(hash).offset().top - 70
				},
				100,
				function() {}
			);
		}
		}
		}
		else {
			
				var hash = this.hash;
				window.location.href = hash;
				
		}
	});

	$('.getQuote').on('click',function(){
		setTimeout(() => {
			$('#treatmentForm').find('input[name="medialTreatment"]').focus();
		}, 110);
	})
	

	$('.whyDoctoorum .text-list').find('h3').on('click',function(){
		var selectedIndex = $(this).attr('class').split('-')[1][0];
		$(this).addClass('active').siblings().removeClass('active');
		$('.text-details').find('p.whyDoctourm-content-'+selectedIndex).addClass('show').siblings().removeClass('show');

	})
	// owl carosuel for blog
	$('.slider').owlCarousel({
		loop: true,
		center: true,
		margin: 60,
		nav: false,
        dots:false,
		items: 1,
		navText: ['<i class="material-icons">chevron_left</i>','<i class="material-icons">chevron_right</i>'],
		responsive:{
			0:{
				items:1,
				dots:false,
				nav:false,
				margin:10
			},
			600:{
				items:1,
				dots:false,
				nav:false,
				margin:10

			
			},
			1000:{
				items:1
				
			}
		}
	});

	$('.material-icons.scroll-top').on('click', function(){
		
		$('html, body').animate(
			{
				scrollTop: $('body').offset().top
			},
			100,
			function() {}
		);

	})

});

// Open Navigation Menu
$('header i.material-icons').on('click', function(ev) {
	var current = $(ev.currentTarget);

	if (current.attr('icon') == 'menu') {
		current.attr('icon', 'close');
	} else {
		current.attr('icon', 'menu');
	}

	current.next().toggleClass('--menu-open');
	$('body').toggleClass('--menu-open');
});

// Pre-select the Treatment in Treatment Form, before scrolling down from Treatment Enquire Card
$('#mostPopular .enquire, #treatment .enquire').on('click', function(ev){
	ev.preventDefault();

	var selectedVal = $(this).prev().find('h3').attr('value');

	if(selectedVal !== null) {
		$([document.documentElement, document.body]).animate({
			scrollTop: $("#treatmentForm").offset().top - 70
		}, 100);

		// var options = $('#treatmentForm').find('select[name="medialTreatment"]').find('option');
		
		$('#treatmentForm').find('input[name="medialTreatment"]').val(selectedVal);

		// for (let i = 0; i < options.length; i++) {
		// 	var option = options[i];
		// 	var optionVal = option.value;

		// 	if ( optionVal  == selectedVal ) {
		// 		$(option).attr('selected', true)
		// 	}
		// }
	}

})

// Clear the Treatment in Treatment Form, before scrolling down from Treatment Enquire Card
$('header .getQuote').on('click', function(ev){
	// ev.preventDefault();

	// var selectedVal = $(this).prev().find('h3').attr('value');

	$([document.documentElement, document.body]).animate({
		scrollTop: $("#treatmentForm").offset().top - 70
	}, 100);

	// var options = $('#treatmentForm').find('select[name="medialTreatment"]').find('option');
	
	$('#treatmentForm').find('input[name="medialTreatment"]').val('');

	// for (let i = 0; i < options.length; i++) {
	// 	var option = options[i];
	// 	var optionVal = option.value;
	// 	$(option).attr('selected', false)
	// }

	// $(options[0]).attr('selected', true)

})